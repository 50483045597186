import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div
      className="wrapper"
    >
      <h1 
        data-sal="slide-up"
        data-sal-duration="700"
        data-sal-easing="ease"
        style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <h3
        data-sal="slide-up"
        data-sal-duration="700"
        data-sal-easing="ease"
      >Our little gem</h3>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
